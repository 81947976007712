<template>
  <div>
    <div style="width: 100%; height: 100%" v-if="isLoading" class="d-flex justify-center align-center">
      <page-loader />
    </div>
    <div v-else class="product-page">
      <v-row no-gutters>
        <v-col cols="12" class="page-header d-flexalign-center">
          <span class="page-title">{{ $t('Import Product') }}</span>
          <v-spacer></v-spacer>
        </v-col>
        <v-col cols="12" class="d-flex align-center">
          <v-btn
            @click="(showModalAddLinkProduct = true), (urlAli = ``), (error = '')"
            small
            class="mx-1 my-3"
            outlined
            color="primary"
            ><i class="fas fa-download mr-1"></i>{{ $t('Import') }}
          </v-btn>
        </v-col>
        <v-col v-if="!isLoading" cols="12" class="d-flex">
          <p class="text-normal text-gray m-t">
            List all the products that you have imported from
            <a href="https://aliexpress.com" target="_blank">AliExpress</a> using our Chrome extension or the products'
            URLs. You can customize the product information here before adding them to your store and start selling.
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="data && data.length > 0">
        <v-col cols="12" class="d-flex" v-for="i in data" :key="`k${i._id}`">
          <item-product-ali @delete="getDelete" :item="i" />
        </v-col>
      </v-row>
      <v-row no-gutters v-else>
        <v-col cols="12" class="d-flex justify-center">
          <Empty />
        </v-col>
      </v-row>
      <v-dialog v-model="showModalAddLinkProduct" width="800" style="transform-origin: center top">
        <v-card relative>
          <!-- Title -->
          <v-card-title class="headline"> Import product </v-card-title>
          <div class="close-icon" @click="(showModalAddLinkProduct = false), (urlAli = ``), (error = '')">
            <v-icon>fas fa-times</v-icon>
          </div>
          <v-divider class="mb-3"></v-divider>
          <v-card-text class="">
            <v-text-field
              :error-messages="error"
              v-model="urlAli"
              placeholder="Please enter the URL of products on AliExpress"
            ></v-text-field>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="" @click="(showModalAddLinkProduct = false), (urlAli = ``), (error = '')"> Cancel </v-btn>
            <v-btn :loading="isLoadingButton" color="primary" @click="onSubmit"> Import </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import ItemProductAli from './components/ItemProductAli.vue';
import { apiAliProduct } from '@/apis/aliProduct';
export default {
  components: { ItemProductAli },
  data() {
    return {
      isLoading: false,
      urlAli: ``,
      isLoadingButton: false,
      showModalAddLinkProduct: false,
      srcUrl: 'https://dev.dcomcy.com/api/reviews/public/aliProductInfo/1005001862786358',
      ids: [],
      data: [],
      error: '',
      arrayCheck: [],
    };
  },
  methods: {
    async getDelete(item) {
      try {
        let res = await apiAliProduct.remove(item._id);
        let find = this.data.findIndex(it => item._id === it._id);
        this.data.splice(find, 1);
      } catch (error) {
        //
      }
    },
    async onSubmit() {
      if (this.urlAli) {
        try {
          let isUsZone = this.urlAli.indexOf('aliexpress.us');

          let frist = this.urlAli.indexOf(isUsZone >= 0 ? 'aliexpress.us/item/' : 'aliexpress.com/item/');
          let end = this.urlAli.indexOf('.html');
          let id = this.urlAli.substring(
            frist + (isUsZone >= 0 ? 'aliexpress.us/item/'.length : 'aliexpress.com/item/'.length),
            end,
          );
          if (frist !== -1 && end !== -1 && id) {
            this.isLoadingButton = true;
            let res = await apiAliProduct.getAliProductById(id, isUsZone);
            if (res.data.variants.options.length <= 3) {
              let data = this.convertDataAliToLate(res.data);
              let createResponse = await apiAliProduct.create({ data: { ...data, link: this.urlAli } });
              this.data.unshift({ ...createResponse.data, ...JSON.parse(createResponse.data.data) });
              this.isLoadingButton = false;
              this.urlAli = ``;
              this.showModalAddLinkProduct = false;
            } else {
              console.log('o day');
              this.error =
                'Product with more than 3 options cannot be imported at this time. Please try again with another AliExpress product.';
              this.isLoadingButton = false;
              return;
            }
          } else {
            this.error = 'Url Error';
            this.isLoadingButton = false;
          }
        } catch (error) {
          console.log('error', error);
          this.error = 'Url Error';
          this.isLoadingButton = false;
          //
        }
      }
    },
    objectId() {
      return this.hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => this.hex(Math.random() * 16));
    },
    hex(value) {
      return Math.floor(value).toString(16);
    },
    getListVariant(options, item) {
      // Variant data
      let variantIds = [];
      let getOptionValues = index => {
        // No option, no name or no values
        if (
          typeof options[index] === 'undefined' ||
          !options[index].name ||
          !options[index].values ||
          !options[index].values.length
        ) {
          return [];
        }
        return options[index].values;
      };
      // Option values groupped
      let optionValues = [getOptionValues(0), getOptionValues(1), getOptionValues(2)];
      let optionValuesLength = optionValues.filter(item => item.length > 0).length;
      // Variant data generator
      let variantInit = {
        barCode: null,
        sku: null,
        price: null,
        compareAtPrice: null,
        costPerItem: null,
        taxable: null,
        isDefault: false,
        inventoryPolicy: null,
        requiresShipping: null,
        weightUnit: null,
        weight: null,
        inventoryQuantity: null,
      };
      let generate = () => {
        let listVariants = [];
        for (let i = 0; i < optionValues[0].length; i++) {
          if (optionValuesLength === 1) {
            listVariants.push({
              option1: `${optionValues[0][i].name}`,
              origin1: `${optionValues[0][i].name}`,
              active: true,
              optionValueIds: `${optionValues[0][i].id}`,
              title: `${optionValues[0][i].name}`,
              image: `${optionValues[0][i].image}` || ``,
            });
          }
          if (optionValuesLength === 2) {
            for (let j = 0; j < optionValues[1].length; j++) {
              let checkNullImage2 = () => {
                if (optionValues[0][i].image) {
                  return optionValues[0][i].image;
                }
                if (optionValues[1][j].image) {
                  return optionValues[1][j].image;
                } else return ``;
              };
              listVariants.push({
                option1: `${optionValues[0][i].name}`,
                option2: `${optionValues[1][j].name}`,
                origin1: `${optionValues[0][i].name}`,
                origin2: `${optionValues[1][j].name}`,
                active: true,
                title: `${optionValues[0][i].name}/${optionValues[1][j].name}`,
                optionValueIds: `${optionValues[0][i].id},${optionValues[1][j].id}`,
                image: `${checkNullImage2()}`,
              });
            }
          }
          if (optionValuesLength === 3) {
            for (let j = 0; j < optionValues[1].length; j++) {
              for (let k = 0; k < optionValues[2].length; k++) {
                let checkNullImage = () => {
                  if (optionValues[0][i].image) {
                    return optionValues[0][i].image;
                  }
                  if (optionValues[1][j].image) {
                    return optionValues[1][j].image;
                  }
                  if (optionValues[2][k].image) {
                    return optionValues[2][k].image;
                  } else return ``;
                };
                listVariants.push({
                  option1: `${optionValues[0][i].name}`,
                  option2: `${optionValues[1][j].name}`,
                  option3: `${optionValues[2][k].name}`,
                  origin1: `${optionValues[0][i].name}`,
                  origin2: `${optionValues[1][j].name}`,
                  origin3: `${optionValues[2][k].name}`,
                  active: true,
                  image: `${checkNullImage()}`,
                  optionValueIds: `${optionValues[0][i].id},${optionValues[1][j].id},${optionValues[2][k].id}`,
                  title: `${optionValues[0][i].name}/${optionValues[1][j].name}/${optionValues[2][k].name}`,
                });
              }
            }
          }
        }
        let variants = [];
        for (let i = 0; i < listVariants.length; i++) {
          for (let j = 0; j < item.variants.prices.length; j++) {
            if (listVariants[i].optionValueIds === item.variants.prices[j].optionValueIds)
              variants.push({
                ...listVariants[i],
                inventoryPolicy: 'continue',
                price: item.variants.prices[j].salePrice ? item.variants.prices[j].salePrice : 0,
                cost: item.variants.prices[j].salePrice ? item.variants.prices[j].salePrice : 0,
                compareAtPrice: item.variants.prices[j].salePrice ? item.variants.prices[j].salePrice : 0,
              });
          }
        }
        return variants;
      };
      return generate(0);
      // Tooltip
    },
    generateIdImage(item) {
      let imgOptions = [];
      for (let i = 0; i < item.variants.options.length; i++) {
        for (let index = 0; index < item.variants.options[i].values.length; index++) {
          if (item.variants.options[i].values[index].image) {
            imgOptions.push(item.variants.options[i].values[index].image);
          }
        }
      }
      let a = item.images.concat(imgOptions);
      return a.map(im => {
        let id = this.objectId();
        return { src: im, _id: id };
      });
    },
    generate(args) {
      var r = [],
        max = args.length - 1;
      function helper(arr, i) {
        for (var j = 0, l = args[i].length; j < l; j++) {
          var a = arr.slice(0); // clone arr
          a.push(args[i][j]);
          if (i == max) r.push(a);
          else helper(a, i + 1);
        }
      }
      helper([], 0);
      return r;
    },
    convertVarriant(item) {},
    convertDesciption(item, desc) {
      let specs = ``;
      for (let i = 0; i < desc.length; i++) {
        specs =
          specs +
          `<li data-redactor-style-cache="width: 49%; display: inline-block"><span data-redactor-span="true">${desc[i].attrName}:</span>&nbsp;<span data-redactor-span="true">${desc[i].attrValue}</span><br data-mce-bogus="1"></li>`;
      }
      return `<div>${item.description}<p>Item specifics </p><ul>${specs}</ul></div>`;
    },
    convertDataAliToLate(item) {
      try {
        let obj = {};
        obj.title = item.title;
        obj.vendor = item.storeInfo.name || 'No brand';
        obj.description = this.convertDesciption(item, item.specs);
        obj.imagesRoot = [...item.images].map(it => {
          return {
            src: it,
            status: true,
          };
        });
        obj.images = this.generateIdImage(item);
        obj.keyImages = {};
        for (let index = 0; index < obj.images.length; index++) {
          obj.keyImages[obj.images[index]._id] = obj.images[index].src;
          obj.keyImages[obj.images[index].src] = obj.images[index]._id;
        }
        let checkNameOnly = name => {
          let isOnly = this.arrayCheck.filter(it => {
            return it.displayName === name;
          });
          if (isOnly.length === 1) return name;
          else {
            return `${name} ${isOnly.length}`;
          }
        };
        if (item.variants.options.length > 0) {
          obj.options = item.variants.options.map(it => {
            this.arrayCheck = [];
            return {
              name: it.name,
              values: it.values.map(op => {
                this.arrayCheck = [...this.arrayCheck].concat(op);
                return {
                  name: checkNameOnly(op.displayName),
                  id: op.id,
                  image: obj.keyImages[op.image],
                };
              }),
            };
          });
          obj.variants = this.getListVariant(obj.options, item);
        }
        if (item.variants.options.length === 0 && item.variants.prices.length === 1) {
          obj.options = [{ name: 'title', values: [{ name: 'Default title', id: `1`, image: null }] }];
          obj.variants = [
            {
              active: true,
              optionValueIds: '1',
              image: null,
              option1: 'Default title',
              readOnly: true,
              title: 'Default title',
              price: item.variants.prices[0]?.originalPrice,
              cost: item.variants.prices[0]?.originalPrice,
              compareAtPrice: item.variants.prices[0]?.originalPrice,
            },
          ];
        }
        return obj;
      } catch (error) {
        console.log(error);
      }
    },
    async getData() {
      try {
        this.isLoading = true;
        let res = await apiAliProduct.get();
        console.log(res, 'res');
        // let res = await apiAliProduct.getAliProductById(`32965277211`);
        this.data = res.data.products.map(item => {
          return {
            ...item,
            ...JSON.parse(item.data),
          };
        });
        this.isLoading = false;
        console.log(' this.data ', this.data);
        // this.convertDataAliToLate(res.data);
        // console.log('res', res.data);
      } catch (error) {}
    },
  },
  async mounted() {
    await this.getData();
    // let recaptchaScript = document.createElement('script');
    // recaptchaScript.setAttribute('src', this.srcUrl);
    // document.head.appendChild(recaptchaScript);
  },
};
</script>
