<template>
  <div v-if="item && attributes" class="product-content" style="width: 100%">
    <div class="product-main">
      <div class="section-tabs-and-actions">
        <div class="d-flex align-center">
          <v-checkbox style="visibility: hidden;" />
          <div class="d-flex pointer">
            <div
              @click="clickTabActive(i)"
              :class="tabActive === i.id ? 'active' : ''"
              class="mx-4"
              v-for="i in [
                { name: 'Product', id: 1 },
                { name: 'Description', id: 2 },
                { name: 'Variants', id: 3 },
                { name: 'Images', id: 4 },
              ]"
              :key="i.id"
            >
              <span v-if="i.id === 3">
                {{ i.name }}
                {{ `(${attributes.variants && attributes.variants.length > 0 ? attributes.variants.length : 0})` }}
              </span>
              <span v-else>
                {{ i.name }}
              </span>
            </div>
          </div>
          <v-spacer />
          <div class="d-flex">
            <v-btn @click="deleteItem" small>Delete</v-btn>
            <v-btn @click="imporToProductLat" class="ml-3" small color="primary">Import to Store</v-btn>
          </div>
        </div>
      </div>
      <div class="section-tab-content">
        <div class="content-product" v-show="tabActive === 1">
          <v-row gutters>
            <v-col cols="3">
              <img style="max-width: 100%" :src="attributes.images && attributes.images[0].src" alt="" />
            </v-col>
            <v-col cols="9">
              <label class="font-weight-bold" for="">Original title:</label>
              <div>
                <a :href="item.link" target="_blank" rel="noopener noreferrer">
                  {{ item.title }}
                </a>
              </div>
              <label for="" class="font-weight-bold"> Change title </label>
              <v-textarea v-model="attributes.title"></v-textarea>
            </v-col>
          </v-row>
        </div>
        <div class="content-product" v-show="tabActive === 2">
          <ContentInput
            :height="400"
            :minHeight="400"
            :model="`description`"
            title="Description"
            subtitle="Maximum 100.000 characters"
          />
        </div>
        <div class="content-product" v-show="tabActive === 3">
          <table id="table-ali" style="max-height: 500px; overflow-y: auto; display: inline-block; overflow-x: scroll">
            <tr>
              <th></th>
              <template v-if="attributes.options && attributes.options.length > 0">
                <th v-for="i in attributes.options" :key="i.name">
                  {{ i.name }}
                </th>
              </template>
              <!-- <th>Country</th>
              <th>Contact</th> -->
              <th>Cost</th>
              <th>Price</th>
              <th>Compare at price</th>
              <th>
                Base Cost
                <br />
                <small class="green--text">(Included Shipping)</small>
              </th>
              <th>Profit</th>
            </tr>
            <tbody>
              <tr>
                <td></td>
                <td v-for="i in attributes.options" :key="i.name" style="width: 150px"></td>
                <!-- <td style="width: 200px"></td> -->
                <td></td>
                <td style="width: 140px">
                  <apply-item @apply="getApply" :item="item" :type="`price`" />
                </td>

                <td style="width: 140px">
                  <apply-item @apply="getApply" :item="item" :type="`compareAtPrice`" />
                </td>
                <td style="width: 140px">
                  <apply-item @apply="getApply" :item="item" :type="`baseCost`" />
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr v-for="i in attributes.variants" :key="`a${i.title}`">
                <td class="text-center">
                  <img style="width: 50px" :src="attributes.keyImages[i.image]" alt="" />
                </td>
                <td style="width: 150px" v-for="(a, k) in attributes.options" :key="a.name">
                  <v-text-field
                    :readonly="i.readOnly"
                    @input="changeOption(`${k + 1}`, i)"
                    hide-details
                    @blur="update"
                    style="width: 140px"
                    v-model="i[`option${k + 1}`]"
                  ></v-text-field>
                </td>
                <td>{{ currency.priceFormat(i.cost) }}</td>
                <td style="width: 140px">
                  <v-text-field prefix="$" hide-details style="width: 140px" v-model="i.price"></v-text-field>
                </td>
                <td style="width: 140px">
                  <v-text-field prefix="$" hide-details style="width: 140px" v-model="i.compareAtPrice"></v-text-field>
                </td>
                <td style="width: 140px">
                  <v-text-field prefix="$" hide-details style="width: 140px" v-model="i.baseCost"></v-text-field>
                </td>
                <td style="width: 100px; color: #20a848">{{ currency.priceFormat((i.price - i.cost).toFixed(2)) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="content-product" v-show="tabActive === 4">
          <v-row gutters class="d-flex">
            <div v-for="(i, k) in attributes.imagesRoot" :key="`a${k}`" class="pa-1 tab-image--ali">
              <v-card class="checkbox">
                <v-checkbox hide-details="" v-model="i.status" />
              </v-card>

              <img style="max-width:200px" :src="i.src" alt="" srcset="" />
            </div>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentInput from '@/views/website/components/Editor';
import { productApi } from '@/apis/product';
import ApplyItem from './ApplyItem.vue';
import { apiAliProduct } from '@/apis/aliProduct';
import currency from '@/helpers/currency';

export default {
  props: {
    item: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    ContentInput,
    ApplyItem,
  },
  data() {
    return {
      change: {
        price: null,
        compareAtPrice: null,
      },
      attributes: {},
      tabActive: 1,
      init: false,
      executor: null,
      currency,
    };
  },
  watch: {
    item: {
      handler() {
        this.attributes = Object.assign({}, this.item);
      },
      deep: true,
    },
    attributes: {
      handler() {
        if (this.init) {
          if (this.executor) {
            clearTimeout(this.executor);
            this.executor = null;
          }
          this.executor = setTimeout(async () => {}, 500);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.attributes = Object.assign(
      {},
      {
        ...this.item,
        variants: this.item.variants.map(variant => {
          variant.baseCost = 0;
          return variant;
        }),
      },
    );
    this.$forceUpdate();
    this.changeOption();
    this.init = true;
  },
  methods: {
    async update() {
      if (this.enableUpdate) {
        let res = await apiAliProduct.update(this.item._id, { data: this.attributes });
        // console.log('res', res);
      }
      this.enableUpdate = false;
    },
    changeOption(option, data) {
      this.enableUpdate = false;
      if (option && data) {
        let value = Object.assign({}, data);
        try {
          let root = value[`origin${option}`];
          let variantsNew = this.attributes.variants.map(item => {
            return {
              ...item,
              [`origin${option}`]:
                item[`origin${option}`] === root ? value[`option${option}`] : item[`option${option}`],
              [`option${option}`]:
                item[`origin${option}`] === root ? value[`option${option}`] : item[`option${option}`],
            };
          });
          this.attributes = {
            ...this.attributes,
            variants: variantsNew,
          };
          let optionNew = {
            name: this.attributes.options[parseInt(option) - 1].name,
            values: this.attributes.options[parseInt(option) - 1].values.map(v => {
              return {
                ...v,
                name: root === v.name ? value[`option${option}`] : v.name,
              };
            }),
          };
          this.attributes.options[parseInt(option) - 1] = Object.assign(
            {},
            {
              ...optionNew,
              values: optionNew.values.map(it => it),
            },
          );
          // this.attributes = Object.assign({}, a);
          this.$forceUpdate();
        } catch (error) {
          console.log(error, 'error');
        }
        this.enableUpdate = true;
      } else {
        this.attributes = Object.assign(
          {},
          {
            ...this.item,
            options: this.item.options.map(item => {
              return {
                ...item,
                values: item.values.map(it => {
                  return {
                    ...it,
                    name: it.name,
                  };
                }),
              };
            }),
            imagesRoot: this.item.imagesRoot.map(it => {
              return {
                ...it,
                status: it.status,
                src: it.src,
              };
            }),
            images: this.item.images.map(it => {
              return {
                ...it,
                _id: it._id,
                src: it.src,
              };
            }),
            variants: this.item.variants.map(it => {
              return {
                ...it,
                option1: it.option1,
                option2: it.option2,
                option3: it.option3,
              };
            }),
          },
        );
      }
    },
    async getApply(data) {
      const caculate = item => {
        console.log('item', item);
        if (data.typeChangePrice.id === 1) return (item * data.value).toFixed(2);
        if (data.typeChangePrice.id === 2) return (item + data.value).toFixed(2);
        if (data.typeChangePrice.id === 3) return data.value.toFixed(2);
      };
      const applyData = variants => {
        return variants.map(item => {
          return {
            ...item,
            [data.type]: parseFloat(caculate(item.cost)),
          };
        });
      };
      let copyVariants = Object.assign({}, this.attributes);
      let newAttributes = {
        ...this.attributes,
        variants: applyData(this.attributes.variants),
      };
      this.attributes = Object.assign({}, newAttributes);
      this.enableUpdate = true;
      this.update();
      // this.attributes;
      // copyVariants;
      // console.log(data);
    },
    changeImage() {
      let newImage = [];
      let removeImage = this.attributes.imagesRoot.filter(item => !item.status);
      // newImage = this.attributes.images.filter(item => {
      //   return   removeImage.includes(x)
      // });
      newImage = this.attributes.images.filter(({ src: src1 }) => !removeImage.some(({ src: src2 }) => src1 === src2));
      return newImage;
    },
    deleteItem() {
      this.$emit('delete', this.item);
    },
    openChangeAllPrice(type, item) {
      console.log('e,type, item', type, item);
    },
    async imporToProductLat() {
      try {
        let convertData = {
          ...this.attributes,
          bodyHtml: this.attributes.description,
          variants: this.attributes.variants.map(it => {
            return {
              ...it,
              price: parseFloat(it.price ? it.price : 0),
              compareAtPrice: parseFloat(it.compareAtPrice ? it.compareAtPrice : 0),
              baseCost: parseFloat(it.baseCost ? it.baseCost : 0),
              image: it.image && it.image !== 'undefined' ? it.image : null,
              imageSrc: it.image && it.image !== 'undefined' ? this.attributes.keyImages[it.image] : null,
            };
          }),
          options: this.attributes.options.map(item => {
            return {
              name: item.name,
              values: item.values.map(i => i.name),
            };
          }),
        };
        convertData.images = this.changeImage();
        delete convertData._id;
        await productApi.create(convertData);
        this.$emit('delete', this.item);
      } catch (error) {
        //
      }
    },
    setContent(content) {},
    clickTabActive(i) {
      this.tabActive = i.id;
    },
  },
};
</script>

<style lang="scss">
.product-content {
  #table-ali {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #table-ali td,
  #table-ali th {
    border-bottom: 1px solid #ddd;
    padding: 8px;
  }
  #table-ali th {
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
    // background-color: var(--v-primary-base);
    color: black;
    text-transform: uppercase;
  }
  padding: 5px;
  .content-product {
    min-height: 200px;
  }
  .product-main {
    .tab-image--ali {
      position: relative;
      img {
        position: relative;
        outline: 1px solid black;
      }
      .checkbox {
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 10;
        width: 24px;
        height: 24px;
        .v-input__control {
          margin-top: -20px;
        }
      }
    }
    .price-wrapper {
      position: relative;
      .input-add-price {
        z-index: 10000;
        right: 0px;
        width: 350px !important;
        position: absolute;
        top: -43px;
      }
    }
    padding-left: 10px;
    padding-right: 10px;
    // padding: 0px 3px;
    border-radius: 12px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
    box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%);
    .section-tabs-and-actions {
      //   padding: 0 16px;
      .active {
        border-bottom: 1px solid #000;
      }
    }
    .section-tab-content {
      border-top: 1px solid #dbdfe1;
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
</style>
